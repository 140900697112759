import React from "react"
import Button from"./button"
import Slider from "../components/slider"
import presets, { colors } from "../utils/presets"
import { rhythm, scale } from "../utils/typography"
import { vP, vPHd, vPVHd, vPVVHd } from "../components/gutters"
import { StaticQuery, graphql, Link } from "gatsby"
import MobileImg from "../images/tu2.png"
import TabletImg from "../images/tu1.png"

const MastheadContent = () => (
    <div className="masthead-content" css={styles.sloganPosition}>
        <div>
            <h1 css={styles.slogan}>
                <span css={{ display: `block` }}>
                  <StaticQuery
                    query={pageQuery}
                    render={data => {
                      const _header = data.markdownRemark
                      return (
                        <>
                          <div css={styles.span}>
                          {_header.frontmatter.title}
                          </div>
                          <Slider items={_header.frontmatter.items} />
                        </>
                      )
                    }}
                  />
                </span>
            </h1>
            <div css={{
              marginTop: '5rem',
              [presets.Tablet]: {
                marginTop: '6.5rem',
              }
            }}>
              <Button large to="/quickstart/" overrideCSS={styles.button} style={{textDecoration: 'none'}}>
                  快速上手 →
              </Button>
              <span css={{
                display: 'block',
                [presets.Tablet]: {
                  display: 'inline-block',
                }
              }}>
                <Button large to="/online/" overrideCSS={{
                  ...styles.button,
                  marginTop: 0,
                }} style={{textDecoration: 'none'}}>
                    在线体验 →
                </Button>
              </span>
            </div>
        </div>
        <div>
          <Link to="/product/">
            <div css={styles.imgBlock}>
               <div css={styles.mobileImg}><img src={MobileImg} alt="" /></div>
               <div css={styles.tabletImg}><img src={TabletImg} alt="" /></div>
               <div css={styles.pDesc}>细节精致到位</div>
            </div>
          </Link>

        </div>
    </div>
)

// const items = [
//   `减少运营成本`, `保障业务应用稳定`, `提高运维服务质量`, `多维度了解IT运营情况`,
// ]

const styles = {
    slogan: {
      ...scale(0.7),
      color: colors.skyDark,
      lineHeight: 1.2,
      margin: 0,
      marginBottom: `1.2em`,
      padding: 0,
      paddingLeft: rhythm(presets.gutters.default / 2),
      width: rhythm(10),
      [presets.Mobile]: {
        width: rhythm(10),
        paddingLeft: vP,
      },
      fontSize: scale(3 / 5).fontSize,
      "@media (min-width: 350px)": {
        fontSize: scale(4 / 5).fontSize,
      },
      "@media (min-width: 650px)": {
        fontSize: scale(4 / 5).fontSize,
        width: rhythm(10),
      },
      "@media (min-width: 840px)": {
        fontSize: scale(1.1).fontSize,
        width: rhythm(14),
      },
      [presets.Phablet]: {
        paddingLeft: vP,
      },
      [presets.Hd]: {
        fontSize: scale(1.4).fontSize,
        width: rhythm(16),
      },
      [presets.VHd]: {
        fontSize: scale(1.4).fontSize,
        width: rhythm(18),
        paddingLeft: vPHd,
      },
      [presets.VVHd]: {
        fontSize: scale(1.4).fontSize,
        width: rhythm(20),
        paddingLeft: vPVHd,
      },
    },
    sloganPosition: {
      padding: vP,
      paddingTop: rhythm(0.85),
      paddingBottom: rhythm(0.85),
      flexGrow: `0`,
      flexShrink: `1`,
      alignItems: 'center',
      justifyContent: 'space-between',
      [presets.Mobile]: {
        paddingBottom: rhythm(2),
      },
      [presets.Phablet]: {
        // paddingRight: 0,
      },
      [presets.Tablet]: {
        // paddingTop: rhythm(3),
        display: `flex`,
      },
      [presets.Desktop]: {
        // paddingTop: rhythm(3),
      },
      [presets.Hd]: {
        // paddingTop: rhythm(4),
        paddingLeft: vPHd,
        paddingRight: vPHd,
        // paddingBottom: rhythm(4),
      },
      [presets.VHd]: {
        // paddingTop: rhythm(4),
        paddingLeft: vPVHd,
        paddingRight: vPVHd,
      },
      [presets.VVHd]: {
        // paddingTop: rhythm(1),
        paddingLeft: vPVVHd,
        paddingRight: vPVVHd,
        // paddingBottom: rhythm(2.5),
      },
    },
    span: {
        [presets.Tablet]: {
        marginRight: rhythm(1 / 8),
        },
    },
    button: {
      // marginTop: `3rem`,
      // marginLeft: `0rem`,
      marginBottom: `1rem`,
      marginLeft: rhythm(presets.gutters.default / 2),
      [presets.Mobile]: {
        marginLeft: vP,
      },
      [presets.Phablet]: {
        marginLeft: vP,
      },
      [presets.VHd]: {
        marginLeft: vPHd + '!important',
      },
      [presets.VVHd]: {
        marginLeft: vPVHd + '!important',
      },
    },
    imgBlock: {
      display: 'block',
      position: 'relative',
      textAlign: 'right',
      // margin: '0 -55px 25% 10px',
      marginRight: 0,
      marginLeft: 36,
      WebkitTransition: 'opacity 0.5s linear, visibility 0.5s linear',
      transition: 'opacity 0.5s linear, visibility 0.5s linear',
      [presets.Tablet]: {
        marginLeft: 0,
        marginRight: -15,
      },
    },
    mobileImg: {
      width: '30%',
      position: 'absolute',
      left: '-26px',
      top: '68px',
      [presets.Phablet]: {
        width: '30%',
      },
      [presets.Tablet]: {
        width: '30%',
      },
    },
    tabletImg: {
      width: '88%',
    },
    link: {
      color: '#fff',
      "&:hover": {
        color: '#fff',
        textDecoration: 'underline'
      }
    },
    pDesc: {
      position: 'absolute',
      left: '32%',
      bottom: '-35px',
      color: '#fff',
      fontSize: '1.8rem'
    }
}

export default MastheadContent

const pageQuery = graphql`
  query HomeHeaderQuery {
    markdownRemark(
      frontmatter: { type: { eq: "home-header" } }
    ) {
      frontmatter {
        title
        items
      }
    }
  }
`
